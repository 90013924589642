import { dev } from '$app/environment';
import * as Sentry from '@sentry/browser';

if (!dev) {
    Sentry.init({
        dsn: 'https://68b83ffd97850e274c1bdc30bab1615f@o4508022227533824.ingest.us.sentry.io/4508022229041152',

        integrations: [
            Sentry.httpClientIntegration(), // Captures errors on failed requests from Fetch and XHR
            Sentry.browserTracingIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.captureConsoleIntegration({ levels: ['error'] })
        ],

        attachStacktrace: true, // stack traces are automatically attached to all messages logged
        ignoreErrors: [
            'This is an expected result when the User already exists. Push subscription is being transferred the existing User.' // One Signal non-error error... 🙄
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,

        // Set profilesSampleRate to 1.0 to profile every transaction.
        // Since profilesSampleRate is relative to tracesSampleRate,
        // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
        // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
        // results in 25% of transactions being profiled (0.5*0.5=0.25)
        profilesSampleRate: 0.5
    });
}

/** @type {import('@sveltejs/kit').HandleClientError} */
export async function handleError({ error, event, status, message }) {
    if (status === 404 && event.url.pathname.includes('/api/auth')) {
        // Client errors when goto/a is to an internal api AND it returns a redirect, ignore api oauth flow as it actually works.
        return;
    }

    if (!dev) {
        Sentry.captureException(error);
    }

    // Rethrow
    throw error;
}
